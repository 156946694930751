import './App.css';

import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Switch,
  FormControlLabel,
} from '@mui/material';

function App() {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [direction, setDirection] = useState('toGenZ'); // Default translation direction

  const handleTranslate = async () => {
    try {
      const response = await fetch(
        'https://backend-production.spicious.workers.dev',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ direction, text: input }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.translatedText) {
        setOutput(data.translatedText);
      } else {
        setOutput('Translation failed.');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setOutput('Translation failed due to a network or server error.');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: 'linear-gradient(45deg, #1de9b6, #08aeea)', // Apply gradient here
      }}
    >
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1, // Take up all available space
          padding: '50px',
        }}
      >
        <div style={{ paddingTop: '0px' }}>
          <Typography variant='h4' gutterBottom>
            Gen Z Translator
          </Typography>
        </div>

        <TextField
          label='Enter your text'
          variant='outlined'
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          style={{ marginBottom: '20px', width: '80%' }}
        />

        {output && <Typography variant='h5'>{output}</Typography>}

        <FormControlLabel
          control={
            <Switch
              checked={direction === 'toEnglish'}
              onChange={() =>
                setDirection((prev) =>
                  prev === 'toGenZ' ? 'toEnglish' : 'toGenZ'
                )
              }
            />
          }
          label={
            direction === 'toGenZ' ? 'English to Gen Z' : 'Gen Z to English'
          }
          style={{ marginBottom: '10px' }}
        />

        <Button
          variant='contained'
          color='primary'
          onClick={handleTranslate}
          style={{ marginBottom: '20px' }}
        >
          Translate
        </Button>

        <a href='https://shop.talktogenz.com' style={{ paddingTop: '20px' }}>
          <Typography variant='h4' gutterBottom>
            🔥 Merch
          </Typography>
        </a>
      </Container>

      {/* Spacing for scrolling effect
      <div style={{ height: '20vh' }}></div> */}

      {/* Products container with top margin for scrolling */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
          marginBottom: '100vh', // Add some space below the products
        }}
      >
        {/* Product components wrapped in a div with specified size and object-fit for images */}
        <div style={{ width: '300px', height: '300px' }}>
          <div
            id='product-component-1713542329130'
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          ></div>
        </div>
        <div style={{ width: '300px', height: '300px' }}>
          <div
            id='product-component-1713542648225'
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default App;
